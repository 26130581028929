import 'antd/lib/button/style/css'

import React from 'react'

import Layout from '../components/Layout'

const IndexPage =
    () => {
      return (<Layout><div><div align = 'center'><br /><p style = {
        {
          color: 'cornflowerblue', fontSize: 50, fontWeight: 'bold'
        }
      }>Tryna Spell</p>
        </div></div>
    </Layout>)
    }

export default IndexPage
